<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
      <a href="/"><img style="padding: 4%;" src="@/assets/logo.png" width="120px" alt=""></a> &nbsp;
      <a href="https://pay.sumup.com/b2c/Q6Q2WFKS" class="btn btn-outline-success" style="border-radius: 23px;">Donate Now</a> &nbsp;
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        @click="visible = !visible">
        <span class="navbar-toggler-icon"></span>
      </button> &nbsp;
      <div class="navbar-collapse" :class="!visible ? 'collapse' : ''" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a href="/#medical">Health</a> &nbsp;
          </li>
          <li class="nav-item">
            <a href="/#building"> Housing</a> &nbsp;
          </li>
          <li class="nav-item">
            <router-link to="/contact"> Contact</router-link> &nbsp;
          </li>
          <li>
            <router-link to="/shop"> Shop</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <router-view />
</template>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  background-color: #ffffff;
}

html,
body {
  font-family: 'Roboto', sans-serif;
}

nav {
  text-align: center;
  padding: 4px;
  position: fixed;
  top: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(161deg, rgba(255, 255, 255, 1) 4%, rgba(242, 240, 244, 1) 32%, rgba(239, 236, 241, 1) 62%, rgba(225, 220, 229, 1) 77%, rgba(220, 215, 225, 1) 90%, rgba(230, 223, 237, 1) 99%);
  width: 100%;
  z-index: 10 !important;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
