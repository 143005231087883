<template>
  <div class="container-fluid" style="margin-top: 1%;">
    <section class="banner">
      <div class="container">
        <div class="row" style="min-height: 55vh;padding-top: 30px;">
          <div class="col-lg-9">
            <h1>Africa's future is refreshing.</h1>
            <p>Our mission is to empower communities in developing countries in Africa.
              We want them to improve their situation in a self-reliant and sustainable way.
            </p>
            <p>Our organization has the following specific objectives:
            <ol>
              <li>Mitigate the most frequent causes of negative health effects by purchasing
                remedies and equipment not readily available.</li>
              <li>Facilitate knowledge transfer within target communities and from sources
                from sources outside Africa in the following areas:
                <ul>
                  <li>Health care</li>
                  <li>Affordable housing</li>
                  <li>Agriculture</li>
                </ul>
              </li>
            </ol>
            </p>
          </div>
          <div class="col-lg-3">
            <img src="@/assets/etoo.png" width="100%" alt="">
          </div>
        </div>
      </div>
    </section>
    <br>
    <div class="jumbotron">
      <div style="background-color: rgba(255, 255, 255, 0.795); padding: 2%; border-radius: 23px; width:70%">
        <h2>Join our list to receive updates.
        </h2>
        <br>
        <div class="container">
          <form target="_blank" action="https://formsubmit.co/el/danowu" method="POST">
            <div class="form-group">
              <input type="text" name="name" class="form-control" placeholder="Name" required>
              <input type="email" name="email" class="form-control" placeholder="Your email" required> <br>
              <button type="submit" class="btn btn-lg btn-success btn-block">Send</button>
            </div>
          </form>
        </div>
      </div>

    </div>
    <div class="container">
      <br>
      <div class="row" id="medical">
        <h1 style="text-align: center;">Our medical initiatives</h1>
        <div class="col-lg-7">
          <img src="@/assets/enfant.jpeg" width="100%" style="border-radius: 5%;" alt="">
        </div>
        <div class="col-lg-5" style="margin-top: 50px;">
          <h3>Making an impact.</h3>
          <p>
            We give out free medication and dietary supplements to healthcare facilities in Africa.
            While these items might be cheap in the West, many are more expensive and often very difficult
            to come by in developing countries. This is even more exaggerated in rural settings.
          </p>

          <p>
            We hope to improve the quality of life and health outcomes for people in Africa.
          </p>
        </div>
      </div>
      <hr>
      <br>
      <div class="row" style="text-align: center;">
        <h3>Projects</h3>
        <br>
        <div class="card-deck">
          <div class="card">
            <div class="card-body">
              <img src="@/assets/thera.jpg" width="100%" style="border-radius: 5%;" alt="">
              <hr>
              <h5 class="card-title">Physical Therapy</h5>
              <p class="card-text">Many of the physical maladies in Africa are related to labour. By partnering
                with physical therapists, we help families continue to earn money and enjoy their lives.
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <img src="@/assets/emmanuel.jpeg" width="100%" style="border-radius: 5%;" alt="">
              <hr>
              <h5 class="card-title">Community Clinic</h5>
              <p class="card-text">The health care sector is a main pillar of every community. One of our goals
                is to help heavily visited clinics by giving them the medication they need.
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <img src="@/assets/chospital.jpeg" width="100%" style="border-radius: 5%;" alt="">
              <hr>
              <h5 class="card-title">Children's Hospital</h5>
              <p class="card-text">We make sure aside a substantial portion of our donations for the most
                vulnerable among us. Good care and adequate nutrition are essential for children.
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <img src="@/assets/waffle.jpg" width="100%" style="border-radius: 5%;" alt="">
              <hr>
              <h5 class="card-title">Community initiatives</h5>
              <p class="card-text">A small gesture can make a world of difference for the chronically ill.
                Knowing that you are not forgotten by society can brighten someone's day.
              </p>
            </div>
          </div>
        </div>
      </div>
      <br>
      <br>
      <div class="row">
        <div class="col-lg-6" style="margin: auto;">
          <h3>Every little bit counts.</h3>
          <p>We know that some people may not want
            to donate money and give medicine instead.
            We understand, and it would be greatly appreciated.
            Due to safety concerns, we only accept unopened medication
            in its original packaging.
          </p>
          <p>Contact us so we can arrange to pick it up or pay for shipping.</p>
          <router-link to="/contact"><button class="btn btn-dark">Learn more</button></router-link>
        </div>
        <div class="col-lg-6" style="text-align: center;">
          <img src="@/assets/medbox.jpeg" width="50%" style="border-radius: 50%;border: white;" alt="">
        </div>
      </div>
      <br>
      <br>
      <div class="row" style="background-color: rgb(241, 241, 231); padding-top: 2%;padding-bottom: 2%;">
        <div class="col-lg-8">
          <img src="@/assets/pikin.jpeg" width="100%" style="border-radius: 5%;" alt="">
        </div>
        <div class="col-lg-4" style="margin: auto;padding:10px;">
          <h3>We're in the community.</h3>
          <p>
            We never want to lose sight of why we are doing what we are doing.
          </p>

          <p>
            We go into target communities and talk to the people directly. The insight we get from this informs
            our future actions and initiatives.
          </p>
        </div>
      </div>
    </div>
    <br>
    <div class="container-fluid">
      <div class="banner">
        <div class="container" style="min-height: 60vh;">
          <div id="building" class="row">
            <h1 style="text-align: center;padding: 2%;">Affordable housing can be dignified</h1>
            <div class="col-lg-6">
              <img src="@/assets/group.jpg" style="border-radius: 5%;" width="100%" alt="">
            </div>
            <div class="col-lg-6">
              <p>Recognizing the link between sanitation and health, we look to create beautiful, affordable homes that
                deal with waste
                properly.</p>
              <p>The work has already begun and we look forward to engaging with the international community as we
                explore cost effective options for
                housing.</p>
            </div>

          </div>
        </div>
      </div>
    </div>
    <hr>
    <div style="text-align: center;">contact@refreshafrica.org</div>
    <hr>
  </div>
</template>

<script>
export default {
  name: 'HomeView',

}
</script>

<style>
.banner {
  background: rgb(255, 255, 255);
  background: linear-gradient(161deg, rgba(255, 255, 255, 1) 4%, rgba(242, 240, 244, 1) 32%, rgba(239, 236, 241, 1) 62%, rgba(225, 220, 229, 1) 77%, rgba(220, 215, 225, 1) 90%, rgba(230, 223, 237, 1) 99%);
}

.jumbotron {
  padding: 2rem 1rem;
  margin: auto;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem;
  width: 90vw;
  background-image: url("@/assets/ankara.jpeg");

  color: #000000;
}
</style>